import {ApolloError} from '@apollo/client';
import {Chip, CircularProgress, Grid, Tabs, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import React, {FC, PropsWithChildren, useCallback, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import {AsyncCard} from 'components/async-card-mobile/async-card';
import {FormatCurrency} from 'components/currency/format-currency';
import {GraphQLErrors} from 'components/graphql-errors';
import {MaturityStatusDisplay} from 'components/opportunities/maturity-status-display';
import {QuoteTotalPrice} from 'components/quotes';
import {PaymentMethodTotalPriceWithTaxDisplay} from 'components/quotes/payment-method-total-price-with-tax-display';
import {useUser} from 'context/user';
import {useMobile} from 'core/hooks';
import {formatDate} from 'core/locale';
import {
    OpportunityFragment,
    useGetOpportunitiesQuery,
    useGetQuotesQuery,
    useGetSalesQuery,
    useGetSupplyOrdersQuery,
} from 'generated/graphql';

enum ButtonChoice {
    quote = 'QUOTE',
    sale = 'SALE',
    supplyOrder = 'SUPPLY ORDER',
    opportunities = 'OPPORTUINITIES'
}

export const LastMovements: FC = () => {
    const {user: {permissions} = {}} = useUser();
    const {classes} = useStyles();
    const buttonList = [
        {label: 'Cotações', button: ButtonChoice.quote, permission: permissions?.viewQuotes, component: <ListQuotes />},
        {label: 'Vendas', button: ButtonChoice.sale, permission: permissions?.viewSales, component: <ListSales />},
        {label: 'Ordens de compra', button: ButtonChoice.supplyOrder, permission: permissions?.viewSupplyOrders, component: <ListSupplyOrders />},
        {label: 'Oportunidades', button: ButtonChoice.opportunities, permission: permissions?.viewOpportunities, component: <ListOpportunities />},
    ];
    const [currentButton, setCurrentButton] = useState(buttonList.find(({permission}) => permission)?.button);

    if (!currentButton) return null;

    return (
        <Grid container={true} spacing={2} item={true} xs={12} className={classes.container}>
            <Grid item={true} xs={12}>
                <Typography variant='h6'>Últimas atualizações</Typography>
            </Grid>
            <Grid item={true} container={true} xs={12} spacing={1}>
                {buttonList.map(({label, button, permission}) => (
                    <>{permission &&
                        <Grid item={true}>
                            <Chip
                                label={label}
                                color={currentButton === button ? 'primary' : 'default'}
                                onClick={() => setCurrentButton(button)}
                            />
                        </Grid>
                    }</>
                ))}
            </Grid>
            <Grid item={true} xs={12}>
                {buttonList.find(({button}) => button === currentButton)?.component}
            </Grid>
        </Grid>
    );
};

type TabsLayoutProps = PropsWithChildren<{loading?: boolean, error?: ApolloError; refetch?: () => any;}>;
function TabsLayout(props: TabsLayoutProps) {
    const {children, loading, error, refetch} = props;
    const {classes} = useStyles();
    const isMobile = useMobile();
    const callbackRef = useHorizontalScroll();

    if (loading) return <CircularProgress />;
    if (error) return <GraphQLErrors error={error} refetch={refetch} />;
    return (
        <Tabs
            variant='scrollable'
            scrollButtons={true}
            value={0}
            indicatorColor='primary'
            className={isMobile ? classes.tabsMobile : ''}
            component='div'
            ref={callbackRef}
            TabIndicatorProps={{style: {display: 'none'}}}>
            {children}
        </Tabs>
    );
}

export const ListQuotes: FC = () => {
    const {classes} = useStyles();
    const {data, loading, error, refetch} = useGetQuotesQuery({variables: {options: {pageSize: 10, page: 0}}, fetchPolicy: 'cache-first'});

    return (
        <TabsLayout loading={loading} error={error} refetch={refetch}>
            {data?.quotes.items.map((row, indexRow) =>
                <Grid item={true} className={classes.gridCard}>
                    <AsyncCard
                        row={row}
                        indexRow={indexRow}
                        rootStyle={{width: '250px', height: '100%'}}
                        noWrapText={true}
                        headers={[
                            {
                                key: 'displayId',
                                render: row => <>Cotação {row.displayId}</>,
                                chipProps: {className: classes.chipType},
                            },
                            {
                                key: 'created',
                                render: row => formatDate(row.created),
                                chipProps: {className: classes.chipDate},
                            },
                        ]}
                        contents={[
                            {key: 'contact.account.name', label: 'Conta'},
                            {key: 'contact.account.owner.name', label: 'Organização'},
                            {key: 'amount', label: 'Total', render: row => <QuoteTotalPrice quote={row} />},
                        ]}
                        getRowLink={() => ({url: '/quotes/[id]', as: `/quotes/${row.id}`})}
                    />
                </Grid>
            )}
        </TabsLayout>
    );
};

export const ListSales: FC = () => {
    const {classes} = useStyles();
    const {data, loading, error, refetch} = useGetSalesQuery({variables: {options: {pageSize: 10, page: 0}}, fetchPolicy: 'cache-first'});

    return (
        <TabsLayout loading={loading} error={error} refetch={refetch}>
            {data?.sales.items.map((row, indexRow) =>
                <Grid item={true} className={classes.gridCard}>
                    <AsyncCard
                        row={row}
                        indexRow={indexRow}
                        rootStyle={{width: '250px', height: '100%'}}
                        noWrapText={true}
                        headers={[
                            {
                                key: 'displayId',
                                render: row => <>Venda</>,
                                chipProps: {className: classes.chipType},
                            },
                            {
                                key: 'created',
                                render: row => formatDate(row.created),
                                chipProps: {className: classes.chipDate},
                            },
                        ]}
                        contents={[
                            {key: 'quote.contact.account.name', label: 'Conta'},
                            {key: 'quote.contact.account.owner.name', label: 'Organização'},
                            {key: 'amount', label: 'Total', render: row => <QuoteTotalPrice quote={row.quote} paymentMethod={row.paymentMethod} />},
                        ]}
                        getRowLink={() => ({url: '/sales/[id]', as: `/sales/${row.id}`})}
                    />
                </Grid>
            )}
        </TabsLayout>
    );
};

export const ListSupplyOrders: FC = () => {
    const {classes} = useStyles();
    const {data, loading, error, refetch} = useGetSupplyOrdersQuery({
        variables: {options: {pageSize: 10, page: 0, quoteIsnull: ['false']}},
        fetchPolicy: 'cache-first'
    });

    return (
        <TabsLayout loading={loading} error={error} refetch={refetch}>
            {data?.supplyOrders.items.map((row, indexRow) =>
                <Grid item={true} className={classes.gridCard}>
                    <AsyncCard
                        row={row}
                        indexRow={indexRow}
                        rootStyle={{width: '250px', height: '100%'}}
                        noWrapText={true}
                        headers={[
                            {
                                key: 'displayId',
                                render: row => <>Ord. de Compra {row.displayId}</>,
                                chipProps: {className: classes.chipType},
                            },
                            {
                                key: 'created',
                                render: row => formatDate(row.created),
                                chipProps: {className: classes.chipDate},
                            },
                        ]}
                        contents={[
                            {key: '', label: 'Conta', render: row => row.quote?.contact.account.name || '-'},
                            {key: '', label: 'Organização', render: row => row.quote?.contact.account.owner?.name || '-'},
                            {
                                key: 'total',
                                label: 'Total',
                                render: row => row.quote?.sale &&
                                    <PaymentMethodTotalPriceWithTaxDisplay
                                        paymentMethod={row.quote.sale?.paymentMethod}
                                        products={row.orderedProducts.map(product => ({...product.quotedProduct, quantity: product.quantity}))}
                                    /> || '-',
                            },
                        ]}
                        getRowLink={() => ({url: '/supply-orders/[id]', as: `/supply-orders/${row.id}`})}
                    />
                </Grid>
            )}
        </TabsLayout>
    );
};

export const ListOpportunities: FC = () => {
    const {classes} = useStyles();
    const {data, loading, error, refetch} = useGetOpportunitiesQuery({
        variables: {options: {pageSize: 10, page: 0}},
        fetchPolicy: 'cache-first'
    });

    return (
        <TabsLayout loading={loading} error={error} refetch={refetch}>
            {data?.opportunities.items.map((row, indexRow) =>
                <Grid item={true} className={classes.gridCard}>
                    <AsyncCard
                        row={row}
                        indexRow={indexRow}
                        rootStyle={{width: '250px', height: '100%'}}
                        noWrapText={true}
                        headers={[
                            {
                                key: 'displayId',
                                render: row => row.situation.name,
                                chipProps: {className: classes.chipType},
                            },
                            {
                                key: 'updated',
                                format: formatDate,
                                chipProps: {className: classes.chipDate},
                            },
                        ]}
                        contents={[
                            {key: 'contact.account.name', label: 'Conta'},
                            {key: 'estimatedAmount', label: 'Total estimado', render: row => <FormatCurrency number={row.estimatedAmount} />},
                            ...([
                                {key: 'agronomicDevelopment', label: 'Desenvolvimento agronômico'},
                                {key: 'solutionTrust', label: 'Confiança na solução'},
                                {key: 'paymentCondition', label: 'Condição de pagamento'},
                            ] as const).map(({key, label}) => ({
                                key, label,
                                render: (row: OpportunityFragment) => <MaturityStatusDisplay key={key} maturity={row[key]} descKey={key} style={{width: '100%'}} />,
                            })),
                        ]}
                        getRowLink={() => ({url: '/opportunities/[id]', as: `/opportunities/${row.id}`})}
                    />
                </Grid>
            )}
        </TabsLayout>
    );
};

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme: Theme) => ({
    tabsMobile: {
        width: '85vw',
    },

    gridCard: {
        margin: '8px',
        '&:hover': {
            cursor: 'pointer',
        },
    },

    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },

    chipType: {
        color: '#FFB800',
        backgroundColor: 'rgba(255, 194, 37, 0.17)',
    },

    chipDate: {
        backgroundColor: '#F0F0F0',
    }
}));

function useHorizontalScroll() {
    return useCallback((node: HTMLDivElement) => {
        const [child] = node?.getElementsByClassName('MuiTabs-scroller') || [];
        const handleWheel = (event: WheelEvent) => {
            if (!event.deltaY) return;
            child.scrollLeft += event.deltaY + event.deltaX;
            event.preventDefault();
        };
        if (child) child.addEventListener('wheel', handleWheel as EventListener);
    }, []);
}
