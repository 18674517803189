import {MoreVert as MoreVertIcon} from '@mui/icons-material'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Typography,
} from '@mui/material'
import Link from 'next/link'
import {FC, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {LastMovements} from 'components/home/last-movements'
import {HomeItemProps} from 'pages'

export const HomeCards: FC<{list: HomeItemProps[]}> = ({list}) => {
    return (
        <Container style={{width: '100vw'}}>
            <Grid container={true} spacing={4}>
                <Grid item={true} xs={12}>
                    <Typography variant='h5'>
                        Bem Vindo (a) ao Portal de Vendas da Precision Planting!
                    </Typography>
                </Grid>
                <Grid container={true} item={true} xs={12} spacing={2}>
                    {list.map((item, index) => <HomeCard key={index} {...item} />)}
                </Grid>
                <Grid item={true} xs={12}>
                    <LastMovements />
                </Grid>
            </Grid>
        </Container>
    )
}

const HomeCard: FC<HomeItemProps> = ({label, href, permission, mobileOnly, icon, subItems}) => {
    const {classes} = useStyles()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    if (permission === false) return null
    if (mobileOnly) return null

    return (
        <Grid item={true} sm={4} md={3}>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    {subItems && (
                        <div className={classes.moreVertIcon}>
                            <IconButton onClick={event => setAnchorEl(event.currentTarget)} size='large'>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                keepMounted={true}
                                transformOrigin={{vertical: 'top', horizontal: 'center'}}
                                onClose={() => setAnchorEl(null)}>
                                {subItems.map((subItem, index) => (
                                    <MenuItem
                                        key={index}
                                        component='a'
                                        href={subItem.href}
                                        disabled={subItem.permission === false}>
                                        {subItem.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    )}
                    <Box className={classes.icon}>{icon}</Box>
                    <Typography variant='h6'>{label}</Typography>
                </CardContent>
                <CardActions>
                    <Link href={href} passHref={true} style={{width: '100%'}}>
                        <Button className={classes.enterBtn}>
                            Acessar
                        </Button>
                    </Link>
                </CardActions>
            </Card>
        </Grid>
    );
}

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    enterBtn: {
        background: theme.palette.background.default,
        color: theme.palette.primary.main,
        width: '100%',
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.background.paper,
            cursor: 'pointer',
        }
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.spacing(8),
        width: theme.spacing(8),
        borderRadius: theme.spacing(2),
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
    },
    moreVertIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        '&.MuiIconButton-root:hover': {
            background: theme.palette.primary.light
        }
    },
    grid: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    subItem: {
        '&:hover': {
            background: theme.palette.primary.light
        }
    },
    cardContent: {
        '&.MuiCardContent-root': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(1)
        },
    },
}))