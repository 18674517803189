import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from '@mui/icons-material'
import {Box, Collapse, Grid, List, ListItem, ListItemIcon, ListItemText} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {useRouter} from 'next/router'
import React, {FC, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {LastMovements} from 'components/home/last-movements'
import {HomeItemProps} from 'pages'

export const HomeCardsMobile: FC<{list: HomeItemProps[]}> = ({list}) => {
    const {classes} = useStyles()
    return (
        <Grid container={true} className={classes.container}>
            <Grid item={true} xs={12}>
                <List component='nav' style={{width: '100%', padding: '0'}}>
                    {list.map((item, index) => <HomeCardMobile key={index} {...item} />)}
                </List>
            </Grid>
            <Grid item={true} xs={12}>
                <LastMovements />
            </Grid>
        </Grid>
    )
}

const HomeCardMobile: FC<HomeItemProps> = ({href, label, subItems, permission, icon}) => {
    const {classes} = useStyles()
    const router = useRouter()
    const isExpandable = Boolean(subItems)
    const [expanded, setExpanded] = useState<boolean>(router.pathname.startsWith(href))

    if (permission === false) return null

    return (
        <Box className={classes.boxList}>
            <ListItem
                button={true}
                component={isExpandable ? 'button' : 'a'}
                onClick={() => isExpandable ? setExpanded(!expanded) : router.push(href)}
                className={classes.item}>
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                <ListItemText primary={label} className={classes.text} />
                {isExpandable && (expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
            </ListItem>
            <Collapse in={expanded}>
                <List disablePadding={true} className={classes.nestedList}>
                    {!!subItems && subItems.map(subItem => (
                        <ListItem
                            button={true}
                            component={'a'}
                            href={subItem.href}
                            disabled={subItem.permission === false}
                            className={classes.subItem}>
                            <ListItemText primary={subItem.label} />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </Box>
    )
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxWidth: '100vw',
    },

    boxList: {
        height: 'min-content',
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(2),
    },

    item: {
        background: theme.palette.background.paper,
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(2),
    },

    icon: {
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.spacing(6),
        width: theme.spacing(6),
        borderRadius: theme.spacing(2),
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
    },

    text: {
        paddingLeft: theme.spacing(1)
    },

    nestedList: {
        paddingLeft: theme.spacing(8),
    },

    subItem: {
        borderRadius: theme.spacing(2),
        height: '50px',
    }
}))