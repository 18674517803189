import {Chip, ChipProps, Tooltip} from '@mui/material';
import {Theme} from '@mui/material/styles';
import React, {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

import {OpporunityMaturityDescriptionKeys, useOpportunityMaturityTranslations} from 'components/autocompletes';
import {
    OpportunitiesOpportunityAgronomicDevelopmentChoices,
    OpportunitiesOpportunityPaymentConditionChoices,
    OpportunitiesOpportunitySolutionTrustChoices,
    OpportunitiesOpportunityTrackingAgronomicDevelopmentChoices,
    OpportunitiesOpportunityTrackingPaymentConditionChoices,
    OpportunitiesOpportunityTrackingSolutionTrustChoices,
} from 'generated/graphql';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme: Theme) => ({
    chip: {
        fontWeight: 500,
        minWidth: '80px',
        fontSize: '0.75rem',
    }
}));

interface MaturityStatusDisplayProps extends Omit<ChipProps, 'label'> {
    maturity: (
        OpportunitiesOpportunityPaymentConditionChoices | OpportunitiesOpportunityAgronomicDevelopmentChoices | OpportunitiesOpportunitySolutionTrustChoices
        | OpportunitiesOpportunityTrackingAgronomicDevelopmentChoices | OpportunitiesOpportunityTrackingSolutionTrustChoices | OpportunitiesOpportunityTrackingPaymentConditionChoices
    );
    descKey?: OpporunityMaturityDescriptionKeys;
}

export const MaturityStatusDisplay: FC<MaturityStatusDisplayProps> = ({maturity, descKey, ...rest}) => {
    const choices = useOpportunityMaturityTranslations();
    const MATURITIES_STATUS = {
        LOW: {color: '#E33D3D', label: 'Baixo', description: descKey && choices.LOW.description[descKey] || ''},
        MEDIUM: {color: '#FFC700', label: 'Médio', description: descKey && choices.MEDIUM.description[descKey] || ''},
        HIGH: {color: '#00B050', label: 'Alto', description: descKey && choices.HIGH.description[descKey] || ''},
    };

    const {classes} = useStyles();
    const maturityStatus = MATURITIES_STATUS[maturity.toUpperCase() as 'LOW' | 'MEDIUM' | 'HIGH'];

    return (
        <Tooltip title={maturityStatus.description}>
            <Chip {...rest} label={maturityStatus.label} style={{...rest.style, backgroundColor: maturityStatus.color}} className={`${classes.chip} ${rest.className}`} />
        </Tooltip>
    );
};
