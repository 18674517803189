import {
    AttachMoney as AttachMoneyIcon,
    CloudDownload as CloudDownloadIcon,
    Description as DescriptionIcon,
    FeaturedPlayList as FeaturedPlayListIcon,
    LocalShipping,
    LocationCity as LocationCityIcon,
    PaymentOutlined as PaymentOutlinedIcon,
    Person as PersonIcon,
    Settings as SettingsIcon,
    Star as StarIcon,
    TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import {NextPage} from 'next';
import React from 'react';

import {HomeCards} from 'components/cards/home-card';
import {HomeCardsMobile} from 'components/cards/home-card-mobile';
import {CatalogIcon} from 'components/rops/catalog-icon';
import {RopIcon} from 'components/rops/rop-icon';
import {TryAndBuyIcon} from 'components/try-and-buy/try-and-buy-icon';
import {useUser} from 'context/user';
import {loginRequired} from 'core/auth';
import {useMobile} from 'core/hooks';

export interface HomeItemProps {
    label: string;
    description?: string;
    permission?: boolean;
    href: string;
    icon?: React.ReactElement;
    subItems?: {href: string, label: string, permission?: boolean;}[];
    mobileOnly?: boolean;
}

const Index: NextPage = () => {
    const {user} = useUser();
    const permissions = user?.permissions;
    const isMobile = useMobile();

    const pagesList: HomeItemProps[] = [
        {
            href: '/quotes',
            label: 'Cotações',
            icon: <AttachMoneyIcon />,
            permission: permissions?.viewQuotes
        },
        {
            href: '/supply-orders',
            label: 'Ordens de compra',
            icon: <LocalShipping />,
            permission: permissions?.viewSupplyOrders,
            subItems: [
                {href: '/supply-orders', label: 'Lista de ordens de compra'},
                {href: '/supply-orders/order-follow-up', label: 'Acompanhamento de Pedido'}
            ]
        },
        {
            href: '/sales',
            label: 'Vendas',
            icon: <PaymentOutlinedIcon />,
            permission: permissions?.viewSales,
        },
        {
            href: '/accounts',
            label: 'Contas', icon: <LocationCityIcon />,
            permission: permissions?.viewAccounts,
        },
        {
            href: '/contacts',
            label: 'Contatos',
            icon: <PersonIcon />,
            permission: permissions?.viewContacts,
        },
        {
            href: '/opportunities',
            label: 'Oportunidades',
            icon: <StarIcon />,
            permission: permissions?.viewOpportunities,
        },
        {
            href: '/try-and-buy',
            label: 'Try & Buy',
            icon: <TryAndBuyIcon />,
            permission: permissions?.viewTryAndBuy,
            subItems: [
                {href: '/try-and-buy/', label: 'Try & Buy'},
                {href: '/try-and-buy/supply-orders', label: 'Lista de remessas'},
            ]
        },
        {
            href: '/analytics',
            label: 'Estatísticas',
            icon: <TrendingUpIcon />,
            permission: permissions?.viewAnalytics,
            subItems: [
                {href: '/analytics?tab=seedersSurveyed', label: 'Plantadeiras identificadas'},
                {href: '/analytics?tab=potentialOpportunities', label: 'Oportunidades potenciais'},
                {href: '/analytics?tab=quotes', label: 'Cotações'},
                {href: '/analytics?tab=sales', label: 'Vendas'},
                {href: '/analytics?tab=supplyOrders', label: 'Ordens de compra', permission: permissions?.manageShipments},
                {href: '/analytics?tab=warrantyClaims', label: 'Pedidos de garantia', permission: permissions?.viewWarrantyClaimsAnalytics},
                {href: '/analytics?tab=rops', label: 'Relatorios de não conformidade', permission: permissions?.viewRopAnalytics},
                {href: '/analytics?tab=datastudio', label: 'Datastudio', permission: permissions?.viewDatastudio},
            ]
        },
        {
            href: '/warranty-claims',
            label: 'Reivindicação de Garantia',
            icon: <DescriptionIcon />,
            permission: permissions?.viewWarrantyClaim,
        },
        {
            href: '/rops',
            label: 'ROP',
            icon: <RopIcon />,
            permission: permissions?.viewRops,
        },
        {
            href: '/organization-documents',
            label: 'Documentos',
            icon: <CloudDownloadIcon />,
        },
        {
            href: '/stock',
            label: 'Estoque',
            icon: <FeaturedPlayListIcon />,
            permission: permissions?.viewStock,
        },
        {
            href: '/catalog',
            label: 'Catálogo',
            icon: <CatalogIcon />,
            permission: permissions?.viewCatalog,
        },
        {
            href: '/admin',
            label: 'Administração',
            permission: user?.isStaff,
            icon: <SettingsIcon />,
        }
    ];
    return (
        isMobile
            ? <HomeCardsMobile list={pagesList} />
            : <HomeCards list={pagesList} />
    );
};

export default loginRequired(Index);