import {FC} from 'react';

import {FormatCurrency} from 'components/currency/format-currency';
import {MinimalQuotedProductFragment, QuotedPaymentMethodFragment} from 'generated/graphql';
import {calculatePaymentMethodTotalPriceWithTaxDisplay} from 'utils/quotes/calculator';

interface PaymentMethodTotalPriceWithTaxDisplayProps {
    paymentMethod: QuotedPaymentMethodFragment;
    products: Pick<MinimalQuotedProductFragment, 'enableDiscount' | 'price' | 'commissionMultiplier' | 'quantity'>[];
}

export const PaymentMethodTotalPriceWithTaxDisplay: FC<PaymentMethodTotalPriceWithTaxDisplayProps> = ({paymentMethod, products}) => {
    return <FormatCurrency number={calculatePaymentMethodTotalPriceWithTaxDisplay(products, paymentMethod)} />;
};
